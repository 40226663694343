<template lang="pug">
  layout_page(imgCorner="/img/img-contact-polygon.jpg" :callToAction="false")
    template(v-slot:header)
      section.header
        imageParallax(image="/img/headers/contact.jpg" mask="/img/headers/contact-mask.jpg")
        .column.flex_content--vertical.jc_center.ai_center
          hgroup.font_smooth
            h1.text--ls-sm
              b.text--uppercase Contactanos
              span.text--w400 Acércate y Cotiza
    section.contact.margin-bottom--xxxl
      .adjust.margin-bottom--sm
        base-heading(title="Contacto")
        p ¡Tenemos la solución perfecta para tus problemas de izaje!, contáctanos, contamos con una variedad de maquinaria y el plan adecuado para tus necesidades.
      .grid.margin-bottom--xl
        div
          strong.margin-bottom--sm.text--ls-sm.text--uppercase Domicilio
          address.margin-bottom--sm Visitanos en Av. Francisco I. Madero Oriente No. 5714-B Col. Cd. Industrial Ampliación Sur. Morelia, Michoacán, México.
          a(href="https://goo.gl/maps/nZcDJpLxktMrFcih9" alt="Domilicio" target="_blank" rel="noopener")
            span Ver mapa
        div
          strong.margin-bottom--sm.text--ls-sm.text--uppercase Teléfonos
          ul
            li
              a(href="tel:+524433231835" alt="Teléfono")
                span (443) 3 23 18 35
            li
              a(href="tel:+524433234152" alt="Teléfono")
                span (443) 3 23 41 52
      .grid
        div
          strong.margin-bottom--sm.text--ls-sm.text--uppercase Siguenos
          ul.social.flex_content--horizontal
            li
              a(href="https://www.facebook.com/RentaGruasHercules" alt="Facebook" target="_blank" rel="noopener")
                svg.icon
                  use(xlink:href="#icon-facebook")
                span.off_screen Facebook
            li
              a(href="https://www.youtube.com/channel/UCtc9-_LpPZbV_HaUZ9bvsjw" alt="YouTube" target="_blank" rel="noopener")
                svg.icon
                  use(xlink:href="#icon-youtube")
                span.off_screen YouTube
        div
          strong.margin-bottom--sm.text--ls-sm.text--uppercase Correo
          ul
            li
              a(href="mailto:maniobras@gruashercules.mx" alt="Correo electrónico")
                span maniobras@gruashercules.mx
    section.form
      div
        base-heading(title="Formulario" alt)
        p Déjanos tu información y te contactaremos lo antes posible.
        .margin-top--xl
          include_contactForm
</template>
  
<script>
import Page from '@/views/layouts/Page'

export default {
  name: 'page_contact',
  components: {
    layout_page: Page,
    imageParallax: () => import('@/components/ImageParallax.vue'),
    include_contactForm: () => import('@/views/includes/ContactForm.vue')
  },
  metaInfo() {
      return {
          title: 'Contacto',
          meta:[
              {vmid: 'dtn', name:'description', content: 'Te ofrecemos renta de grúas con personal capacitado por hora, día y semana, los 365 días del año.'},
              {vmid: 'kw', name:'keywords', content: 'Grúas Hércules, Hércules, Domicilio, Teléfono de Hércules'},
              {vmid: 'inm', itemprop:'name', content: 'Hércules | Acércate y cotiza'},
              {vmid: 'idtn', itemprop:'description', content: 'Te ofrecemos renta de grúas con personal capacitado por hora, día y semana, los 365 días del año.'},
              {vmid: 'ptt', property:'og:title', content: 'Hércules | Acércate y cotiza'},
              {vmid: 'purl', property:'og:url', content: 'https://gruashercules.mx/contacto'},
              {vmid: 'pdtn', property:'og:description', content: 'Te ofrecemos renta de grúas con personal capacitado por hora, día y semana, los 365 días del año.'},
              {vmid: 'tdtn', name:'twitter:description', content: 'Te ofrecemos renta de grúas con personal capacitado por hora, día y semana, los 365 días del año.'},
              {vmid: 'twtt', name:'twitter:title', content: 'Hércules | Acércate y cotiza'},
          ]
      }
  }
}
</script>